<script lang="ts" context="module">
  import { createInputModalStore } from "./stores";

  type ImageViewModalData = {
    title: string;
    imageLabel: string;
    src: string | null;
    allowRecrop: boolean;
    cropperPrompt: string;
    cropperType: "avatar" | "banner" | "combined";
  };

  export const imageViewModal = createInputModalStore<
    ImageViewModalData,
    {
      cropData: { x: number; y: number; width: number; height: number };
      src: string;
    }
  >();
</script>

<script lang="ts">
  import BasicModal from "./BasicModal.svelte";
  import { cropperModal } from "$lib/components/modals/CropperModal.svelte";
  import { AsyncButton, Spinner } from "$lib/components";
  import { debounce } from "$lib/utils";
  import { apiFetch } from "$lib/api";
  import { showError } from "$lib/components/modals/ErrorModal.svelte";

  export let data: ImageViewModalData;

  let state: "base-view" | "url-upload" = "base-view";
  let rawUrl = "";
  let previewUrl = "";
  let showUrlPreviewSpinner = false;
  let rawUrlValid = false;
  let urlInput: HTMLInputElement | undefined;
  let uploadButton: HTMLButtonElement | undefined;
  let previewImg: HTMLImageElement | undefined;
  let fileInput: HTMLInputElement | undefined;

  $: label = data.imageLabel || "Image";

  const reactToUrlChange = debounce(onUrlChange, 500, { leading: true });
  $: if (rawUrl) reactToUrlChange(rawUrl);
  $: if (state === "base-view") uploadButton?.focus();
  $: if (state === "url-upload") urlInput?.focus();

  async function uploadFromFile() {
    const file = fileInput?.files?.[0];
    if (!file) return;
    const src = URL.createObjectURL(file);
    const crop = await cropperModal.getInput({
      content: {
        title: `Set ${label} Crop`,
        imageLabel: label,
        prompt: data.cropperPrompt,
      },
      aspectRatio: data.cropperType === "avatar" ? 1 : 10 / 3,
      localSrc: src,
      showCirclePreview: true,
    });
    if (!crop) return;
    imageViewModal.confirm({ cropData: crop, src: src });
  }

  async function uploadFromURL() {
    if (!rawUrlValid) return;
    const crop = await cropperModal.getInput({
      content: {
        title: `Set ${label} Crop`,
        imageLabel: label,
        prompt: data.cropperPrompt,
      },
      aspectRatio: data.cropperType === "avatar" ? 1 : 10 / 3,
      localSrc: previewUrl,
      showCirclePreview: true,
    });
    if (!crop) return;
    imageViewModal.confirm({ cropData: crop, src: previewUrl });
  }

  async function uploadFromRecrop() {
    if (!data.src)
      return showError("Recrop Failed", "No image found to recrop.");
    const crop = await cropperModal.getInput({
      content: {
        title: `Set ${label} Crop`,
        imageLabel: label,
        prompt: data.cropperPrompt,
      },
      aspectRatio: data.cropperType === "avatar" ? 1 : 10 / 3,
      localSrc: data.src,
      showCirclePreview: true,
    });
    if (!crop) return;
    imageViewModal.confirm({ cropData: crop, src: data.src });
  }

  function resetToBaseView() {
    state = "base-view";
    rawUrl = "";
    showUrlPreviewSpinner = false;
    rawUrlValid = false;
    previewUrl = "";
  }

  async function onUrlChange(url: string) {
    showUrlPreviewSpinner = true;
    const dataUri = await getDataUriFromUrl(url);
    showUrlPreviewSpinner = false;
    if (!dataUri) {
      rawUrlValid = false;
      return;
    }
    rawUrlValid = true;
    previewUrl = dataUri;
  }

  async function getDataUriFromUrl(url: string) {
    const img = new Image();
    const imgLoaded = new Promise<boolean>((res, rej) => {
      img.onload = () => res(true);
      img.onerror = () => res(false);
    });
    img.src = url;
    try {
      const res = await fetch(url, { mode: "cors" });
      if (!res.ok) return null; // url is valid but image is not
      const blob = await res.blob();
      return URL.createObjectURL(blob);
    } catch (err) {
      if (await imgLoaded) {
        const res = await apiFetch("GET", `/api/upload-by-url?url=${url}`);
        if (!res.ok) return null; // this should never happen
        return res.body.data;
      }
      return null; // url is invalid
    }
  }
</script>

<BasicModal title={data.title} id="avatar-view-modal" store={imageViewModal}>
  {#if state === "base-view"}
    <div class="base-view">
      <div class="button-row">
        <input
          class="d-none"
          accept="image/*"
          type="file"
          bind:this={fileInput}
          on:change={uploadFromFile}
        />
        <button
          class="btn btn-primary"
          type="button"
          bind:this={uploadButton}
          on:click={() => fileInput?.click()}
        >
          Upload new {label}
        </button>
        <button
          class="btn btn-info"
          type="button"
          on:click={() => (state = "url-upload")}
        >
          Upload {label} from URL
        </button>
        {#if data.allowRecrop}
          <button
            class="btn btn-secondary"
            on:click={uploadFromRecrop}
            type="button"
          >
            Re-crop {label}
          </button>
        {/if}
      </div>
      <div class="img-wrapper">
        {#if data.src}
          <img src={data.src} alt={data.title} />
        {:else}
          <p class="my-4">No {label.toLowerCase()} found!</p>
        {/if}
      </div>
    </div>
  {:else if state === "url-upload"}
    <div class="url-upload">
      <div class="input-group">
        <span class="input-group-text">URL</span>
        <input
          class="form-control"
          bind:value={rawUrl}
          bind:this={urlInput}
          on:keyup={({ key }) => {
            if (key === "Enter" && rawUrlValid) uploadFromURL();
          }}
        />
      </div>
      <AsyncButton
        class="btn btn-secondary w-100"
        disabled={!rawUrlValid}
        on:click={uploadFromURL}
      >
        Use This URL
      </AsyncButton>
      <div class="src-preview">
        <img
          bind:this={previewImg}
          src={previewUrl}
          alt="URL Link Preview"
          class:d-none={!rawUrlValid || showUrlPreviewSpinner}
          on:loadstart={() => {
            rawUrlValid = false;
            showUrlPreviewSpinner = true;
          }}
          on:load={() => {
            rawUrlValid = true;
            showUrlPreviewSpinner = false;
          }}
          on:error={() => (showUrlPreviewSpinner = false)}
        />
        {#if showUrlPreviewSpinner}
          <Spinner size="lg" fillSpace />
        {:else if !rawUrlValid}
          <div class="src-preview-error">
            <span>Image Not Loaded</span>
          </div>
        {/if}
      </div>
    </div>
  {/if}

  <svelte:fragment slot="footer">
    {#if state === "base-view"}
      <button
        class="btn btn-danger"
        on:click={imageViewModal.closeModal}
        type="button"
      >
        Close
      </button>
    {:else if state === "url-upload"}
      <button class="btn btn-danger" on:click={resetToBaseView} type="button">
        Back
      </button>
    {/if}
  </svelte:fragment>
</BasicModal>

<style lang="scss">
  .base-view {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .button-row {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      @include breakpoint(md) {
        flex-direction: row;
      }

      button {
        width: 100%;
      }
    }

    .img-wrapper {
      flex-shrink: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: auto;

      img {
        height: auto;
        width: 100%;
      }
    }
  }

  .url-upload {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .src-preview {
      display: flex;
      overflow-y: hidden;
      border-radius: $border-radius;
      height: 300px;
      width: 100%;
      background-color: lighten($bg, 10%);

      img {
        width: 100%;
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
      }

      .src-preview-error {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        span {
          padding: 2rem;
        }
      }
    }
  }
</style>
