<script lang="ts" context="module">
  import { createInputModalStore } from "./stores";
  export type CropData = {
    x: number;
    y: number;
    height: number;
    width: number;
  };
  type CropperModalData = {
    content: {
      title: string;
      imageLabel: string;
      prompt: string;
    };
    localSrc: string;
    showCirclePreview?: boolean;
    aspectRatio?: number;
    noSlide?: boolean;
  };

  export const cropperModal = createInputModalStore<
    CropperModalData,
    CropData
  >();
</script>

<script lang="ts">
  import Cropper from "cropperjs";
  import { onDestroy, onMount } from "svelte";

  import BasicModal from "./BasicModal.svelte";
  import { MouseoverTooltip } from "$lib/components";
  import { debounce } from "$lib/utils";
  import { showError } from "$lib/components/modals/ErrorModal.svelte";
  import { CDN_URL } from "$lib/constants";

  export let data: CropperModalData;

  let cropper: Cropper;
  let cropperImg: HTMLImageElement | undefined;
  let submitButton: HTMLButtonElement | undefined;
  let rawUrl = "";
  let previewUrl = "";
  let rawUrlValid = false;
  let showSpinner = false;

  $: validateData(data);

  onMount(() => {
    submitButton?.focus();
  });

  onDestroy(() => {
    if (cropper) cropper.destroy();
    cropperModal.cancel();
  });

  const reactToUrlChange = debounce(
    async (rawUrl: string) => {
      if (previewUrl === rawUrl) return;
      if (!rawUrl.startsWith("http")) return (previewUrl = "");
      rawUrlValid = false;
      showSpinner = true;
      previewUrl = rawUrl;
    },
    500,
    { leading: true }
  );
  $: reactToUrlChange(rawUrl);
  $: if (cropperImg) cropperReset();

  function cropperReset() {
    if (cropper) cropper.destroy();
    if (!cropperImg) return console.error("NO IMAGE ALL BAD");
    const options: Cropper.Options<HTMLImageElement> = {
      aspectRatio: data.aspectRatio || 1,
      zoomable: true,
      viewMode: 3,
      dragMode: "move",
      rotatable: false,
      autoCropArea: 1,
    };
    cropper = new Cropper(cropperImg, options);
  }

  function submit() {
    const { x, y, width, height } = cropper.getData(true);
    const cropData = { x, y, width, height };
    cropperModal.confirm(cropData);
  }

  async function onCorsMurderingCropper() {
    await showError(
      "Cropping failure",
      "Failed to load image due to issues with image source. You will need to save and re-upload the image instead.  Sorry!"
    );
    return cropperModal.cancel();
  }

  function validateData(data: CropperModalData) {
    if (
      !(
        data.localSrc.startsWith("blob:") ||
        data.localSrc.startsWith("data:") ||
        data.localSrc.startsWith(CDN_URL)
      )
    ) {
      showError(
        "Invalid image source",
        "CropperModal requires a valid image source."
      );
      cropperModal.cancel();
    }
  }
</script>

<BasicModal
  title={data.content.title}
  id="cropper-modal"
  store={cropperModal}
  noSlide={!!data.noSlide}
>
  <div>
    {data.content.prompt}
    <MouseoverTooltip
      tooltip="The selection box can be moved and resized, and the image behind it can be zoomed and moved."
    />
    <button
      class="btn btn-primary w-100 mt-2"
      on:click={submit}
      type="button"
      bind:this={submitButton}
    >
      Submit {data.content.imageLabel}
    </button>
  </div>
  <div class="cropper-external" class:circle-preview={data.showCirclePreview}>
    <div>
      <img
        id="crop-preview"
        src={data.localSrc}
        alt="{data.content.imageLabel} Preview"
        crossorigin="anonymous"
        on:error={onCorsMurderingCropper}
        bind:this={cropperImg}
      />
    </div>
  </div>
</BasicModal>

<style lang="scss">
  img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  .cropper-external {
    padding: 1rem 3rem;
  }
  :global(.circle-preview .cropper-view-box) {
    border-radius: 100%;
  }
</style>
