<script lang="ts" context="module">
  import { createModalStore } from "./stores";

  type ConfirmDeleteModalData = {
    messageHtml: string;
    onConfirm: () => Promise<void>;
  };
  export const confirmDeleteModal = createModalStore<ConfirmDeleteModalData>();
</script>

<script lang="ts">
  import BasicModal from "./BasicModal.svelte";
  import { AsyncButton } from "$lib/components";

  export let data: ConfirmDeleteModalData;

  let deletePromise = Promise.resolve();
</script>

<BasicModal
  title="Confirm Unregister"
  id="confirm-delete"
  store={confirmDeleteModal}
>
  {@html data.messageHtml}
  <svelte:fragment slot="footer">
    <AsyncButton
      class="btn btn-danger"
      promise={deletePromise}
      on:click={() => (deletePromise = data.onConfirm())}
    >
      Unregister
    </AsyncButton>
    <button
      class="btn btn-secondary"
      on:click={confirmDeleteModal.closeModal}
      type="button"
    >
      Cancel
    </button>
  </svelte:fragment>
</BasicModal>
