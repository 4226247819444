<script lang="ts" context="module">
  import { createModalStore } from "./stores";

  type SetGroupModalData = {
    onConfirm: (groupId: number | "null") => Promise<void>;
  };
  export const setGroupModal = createModalStore<SetGroupModalData>();
</script>

<script lang="ts">
  import BasicModal from "./BasicModal.svelte";
  import { sortedGroups } from "$lib/stores/dashboard";
  import { Spinner, SearchInput } from "$lib/components";

  export let data: SetGroupModalData;

  let selectedGroup: number | "null" | null = null;
  let submitPromise = Promise.resolve();

  function submit() {
    if (selectedGroup === null) return;
    submitPromise = data.onConfirm(selectedGroup);
  }
</script>

<BasicModal title="Set Group" id="set-group-modal" store={setGroupModal}>
  <div class="input-panel">
    <SearchInput
      id="tupper-panel-group"
      placeholder="Search for a group"
      chooseText="Choose a group"
      buttonColor="dark"
      values={[...$sortedGroups]}
      selected={selectedGroup}
      on:select={(e) => {
        selectedGroup = e.detail;
      }}
    />
  </div>
  <svelte:fragment slot="footer">
    {#await submitPromise}
      <button class="btn btn-primary disabled" disabled type="button">
        Set Group
        <Spinner size="sm" noFlex />
      </button>
    {:then}
      <button class="btn btn-primary" on:click={submit} type="button">
        Set Group</button
      >
    {/await}
    <button
      class="btn btn-secondary"
      on:click={setGroupModal.closeModal}
      type="button">Cancel</button
    >
  </svelte:fragment>
</BasicModal>

<style lang="scss">
  .input-panel {
    min-height: 380px;
  }
</style>
